import { TextStatement as TextStatementBase } from '@vcc-package/text';
import { ExtendCSS, View } from 'vcc-ui';

import { textStatementWrapperStyle } from './TextStatement.styles';

export type TextStatementProps = {
  title: string;
  text: string;
  extend?: ExtendCSS;
};

const TextStatement: React.FC<TextStatementProps> = ({
  title,
  text,
  extend,
}) => (
  <View extend={[textStatementWrapperStyle, extend]}>
    <TextStatementBase
      title={title}
      text={text}
      alignContent="left"
      innerWrapperExtend={{ untilM: { paddingLeft: 0, paddingRight: 0 } }}
    />
  </View>
);

export default TextStatement;
