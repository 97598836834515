'use client';

import React, { createContext, useMemo, useContext } from 'react';
import { getTranslate } from './get-translate';
import type { Dictionaries } from '.';

export function getDictionariesProvider<M>({
  onError,
}: {
  onError?: (e: string) => void;
} = {}) {
  const emptyTranslate = getTranslate<M>();
  const DictionariesContext = createContext<typeof emptyTranslate | null>(null);
  const DictionariesProvider: React.FC<
    React.PropsWithChildren<{
      dictionaries: Dictionaries;
      locale: string;
      isDev?: boolean;
      forceShowKey?: boolean;
    }>
  > = ({
    children,
    dictionaries,
    locale,
    isDev = process.env.NODE_ENV === 'development',
    forceShowKey,
  }) => {
    const translateMemo = useMemo(() => {
      return getTranslate<M>(dictionaries, locale, {
        isDev,
        forceShowKey,
        onError,
      });
    }, [dictionaries, isDev, forceShowKey, locale]);
    return (
      <DictionariesContext.Provider value={translateMemo}>
        {children}
      </DictionariesContext.Provider>
    );
  };
  const useTranslate = () => {
    const translate = useContext(DictionariesContext);
    if (!translate) {
      if (process.env.NODE_ENV === 'development') {
        console.error(
          'The "useTranslate" hook can only be used  inside a "DictionariesProvider"',
        );
      }
      return emptyTranslate;
    }
    return translate;
  };
  return {
    DictionariesProvider,
    useTranslate,
  };
}
