import { ExtendCSS } from 'vcc-ui';

import { TEXT_BLOCK_MAX_WIDTH } from '@collab/style/customThemeTokens';

export const textStatementWrapperStyle: ExtendCSS = ({
  theme: { baselineGrid },
}) => ({
  width: '100%',
  maxWidth: TEXT_BLOCK_MAX_WIDTH,
  marginTop: baselineGrid * 6,
  fromM: {
    marginTop: baselineGrid * 10,
  },
  '& p': {
    padding: '0 !important',
  },
  '& + *': {
    marginTop: `${baselineGrid * 10}px !important`,
    fromM: {
      marginTop: `${baselineGrid * 16}px !important`,
    },
  },
});
