'use client';

import React, { useCallback, useImperativeHandle, useRef } from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { useTracker } from '@volvo-cars/tracking';
import { type UseDialogOptions, useDialog } from '@volvo-cars/react-headless';
import styles from './DialogRaw.module.css';

export type DialogRawProps = {
  onClose: ({ reason, open }: { reason: string; open: boolean }) => void;
  open: boolean;
  className?: string;
  locked?: boolean;
  trackingOptions?: { trackOnOpen?: boolean; trackOnClose?: boolean };
  size?: 'small' | 'large' | 'full' | 'sheet';
  render: (
    useDialogResults: ReturnType<typeof useDialog>,
  ) => React.ReactElement;
};

// TODO: When React 19 is out, we can use the new ref as a prop instead of forwardRef
// https://react.dev/blog/2024/04/25/react-19#ref-as-a-prop
export const DialogRaw = React.forwardRef<HTMLDialogElement, DialogRawProps>(
  (
    {
      onClose: onCloseCallback,
      open,
      className,
      locked = false,
      size = 'large',
      render,
      trackingOptions = { trackOnOpen: true, trackOnClose: true },
      ...rest
    },
    ref,
  ) => {
    const ga4Tracker = useTracker({}, { mode: 'ga4' });
    const modalRef = useRef<HTMLDialogElement>(null);

    const trackClose = useCallback(() => {
      if (trackingOptions?.trackOnClose) {
        ga4Tracker.customEvent({
          eventAction: 'overlay|close',
          eventLabel: 'close',
          eventCategory: 'dialog close',
        });
      }
    }, [ga4Tracker, trackingOptions?.trackOnClose]);

    // Extract data props for spreading them on dialog
    const dataProps: Record<string, any> = {};
    Object.keys(rest).forEach((key: string) => {
      if (key.startsWith('data')) {
        dataProps[key] = (rest as Record<string, any>)[key];
      }
    });

    const trackOpen = useCallback(() => {
      if (trackingOptions?.trackOnOpen) {
        ga4Tracker.customEvent({
          eventAction: 'overlay|open',
          eventLabel: 'open',
          eventCategory: 'dialog open',
        });
      }
    }, [ga4Tracker, trackingOptions?.trackOnOpen]);

    const onToggle: UseDialogOptions['onToggle'] = ({
      open: onToggleOpen,
      reason,
    }) => {
      if (!onToggleOpen) {
        trackClose();
        onCloseCallback({ reason, open: onToggleOpen });
      } else {
        trackOpen();
      }
    };

    const useDialogResults = useDialog({
      open,
      dismissible: !locked,
      ref: modalRef,
      onToggle,
    });

    useImperativeHandle(
      ref,
      () => useDialogResults.dialogProps.ref.current as HTMLDialogElement,
      [useDialogResults.dialogProps.ref],
    );

    const dialogSizeMap = {
      small: 'dialog-small',
      large: 'dialog-large',
      full: 'dialog-large',
      sheet: 'sheet',
    };

    return (
      <dialog
        {...useDialogResults.dialogProps}
        className={cssMerge(
          dialogSizeMap[size],
          size === 'full' && styles['full-size'],
          className,
        )}
        {...{
          'data-autoid': 'overlay:genericContent',
          ...dataProps,
        }}
      >
        {render(useDialogResults)}
      </dialog>
    );
  },
);

DialogRaw.displayName = 'DialogRaw';
