'use client';

import React from 'react';
import { Markdown } from '@vcc-package/text';
import { Track } from '@volvo-cars/tracking';
import { type DialogProps } from '../dialog/Dialog';
import type { DisclaimerDialogProps as DisclaimerDialogContentProps } from '../../content-management';
import { DialogMain } from '../slots/dialog-main/DialogMain';
import { DialogRaw } from '../components/dialog-raw/DialogRaw';
import { DialogHeader } from '../slots/dialog-header/DialogHeader';
import styles from './DisclaimerDialog.module.css';

export type DisclaimerDialogProps = DialogProps &
  DisclaimerDialogContentProps & {
    titleSource?: string;
    linkSources?: Array<string>;
    descriptionTitleSource?: string;
    descriptionSource?: string;
  };

export const DisclaimerDialog: React.FC<
  React.PropsWithChildren<DisclaimerDialogProps>
> = ({
  open,
  onClose,
  title,
  titleSource,
  descriptionTitle,
  descriptionTitleSource,
  description,
  descriptionSource,
  link,
  linkSources,
}) => (
  <DialogRaw
    onClose={onClose}
    open={open}
    render={({ closeDialog }) => (
      <>
        <DialogHeader onClose={closeDialog} showBackButton={false}>
          <h2 className="font-medium fade-in" data-sources={titleSource}>
            {title}
          </h2>
        </DialogHeader>
        <DialogMain>
          {descriptionTitle && (
            <h2
              className="mb-24 font-24 font-medium"
              data-sources={descriptionTitleSource}
            >
              {descriptionTitle}
            </h2>
          )}
          <div className="flex-col text-secondary items-start font-16">
            <Markdown
              markdown={description}
              data-testid={`disclaimer-dialog:${title}:description`}
              data-sources={descriptionSource}
            />
          </div>
          {link && link.text && (
            <Track eventLabel={`${link.text} | ${link.href}`}>
              <a
                className={`button-text self-center ${styles.disclaimerDialogLink}`}
                data-color="accent"
                aria-label={link.text}
                href={link.href}
                data-sources={linkSources}
              >
                {link.text}
              </a>
            </Track>
          )}
        </DialogMain>
      </>
    )}
  />
);
